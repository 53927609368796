import React, { useEffect, useRef, useState } from 'react';
import { Media } from '../CZ11Cta/CZ11Cta';
import { strapiConfig } from 'helpers/strapiConfig';
import { CustomImage } from 'lib/customImage';
import parse from 'html-react-parser';
import Image from 'frontastic/lib/image';
import { ReferenceLink } from 'helpers/reference';
import useTransformImageSrc from 'helpers/hooks/useTransformImageSrc';
import { useTransform, motion, useScroll } from 'framer-motion';

type CZ13ImageWithText = {
  imageTextItem: ImageTextItem[];
};

export type ImageTextItem = {
  alignment: 'Left' | 'Right';
  title: string;
  subtitle: string;
  text: string;
  image: Media;
  imageAlt: string;
  buttonText: string;
  buttonUrl: string;
};

function CZ13ImageWithText({ imageTextItem }: CZ13ImageWithText) {
  return (
    <section>
      <div className="bg-white">
        {imageTextItem.map((item, idx) => (
          <ImageTextItem
            key={idx}
            imgUrl={`${strapiConfig.url}${item.image.data.attributes.url}`}
            subheading={item.title}
            heading={item.subtitle}
          >
            <ItemContent
              text={item.text}
              title={item.subtitle}
              subtitle={item.title}
              buttonText={item?.buttonText}
              buttonUrl={item?.buttonUrl}
            />
          </ImageTextItem>
        ))}
      </div>
    </section>
  );
}

const ImageTextItem = ({ imgUrl, subheading, heading, children }) => {
  const imgSrc = useTransformImageSrc(imgUrl);
  return (
    <div>
      <div className="relative h-[120vh]">
        <StickyImage imgUrl={imgSrc} />
        <OverlayCopy title={heading} subtitle={subheading} />
      </div>
      {children}
    </div>
  );
};

const StickyImage = ({ imgUrl }: { imgUrl: string }) => {
  const ref = useRef(null);
  const [isScaled, setIsScaled] = useState(false);

  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ['1 1', '1 0'],
  });

  const scale = useTransform(scrollYProgress, [0, 1], [1, 0.85]);
  const opacity = useTransform(scrollYProgress, [0, 1], [0, 1]);

  useEffect(() => {
    const unsubscribe = scale.onChange((latest) => {
      if (latest < 1) {
        setIsScaled(true);
      } else {
        setIsScaled(false);
      }
    });
    return () => unsubscribe();
  }, [scale]);

  return (
    <motion.div
      style={{
        backgroundImage: `url(${imgUrl})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: `calc(100vh - ${12 * 2}px)`,
        top: 12,
        scale: scale,
      }}
      ref={ref}
      className={`sticky z-0 overflow-hidden ${isScaled ? 'rounded-md' : ''}`}
    >
      <motion.div
        className="absolute inset-0 bg-black/70"
        style={{
          opacity,
        }}
      />
    </motion.div>
  );
};

const OverlayCopy = ({ subtitle, title }: { subtitle?: string; title?: string }) => {
  const ref = useRef(null);

  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ['0 1', '1 0'],
  });

  const y = useTransform(scrollYProgress, [0, 1], [250, -250]);
  const opacity = useTransform(scrollYProgress, [0.25, 0.5, 0.75], [0, 1, 0]);

  return (
    <motion.div
      style={{ y, opacity }}
      ref={ref}
      className="absolute left-0 top-0 flex h-screen w-full flex-col items-center justify-center text-white"
    >
      {subtitle && <p className="mb-2 text-center text-xl md:mb-4 md:text-3xl">{subtitle}</p>}
      {title && <p className="text-center text-4xl font-bold md:text-7xl">{title}</p>}
    </motion.div>
  );
};

const ItemContent = ({
  title,
  subtitle,
  text,
  buttonText,
  buttonUrl,
}: {
  title?: string;
  subtitle?: string;
  text?: string;
  buttonText?: string;
  buttonUrl?: string;
}) => {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ['0 1', '1.5 1'],
  });

  return (
    <motion.div
      ref={ref}
      className="mx-auto grid max-w-[1280px] grid-cols-1 gap-8 px-4 pb-24 pt-12 sm:px-10 md:grid-cols-12 lg:px-4"
    >
      <div className="col-span-1 font-bold md:col-span-4">
        {subtitle && <h3 className="mb-4 text-2xl text-primary">{subtitle}</h3>}
        {title && (
          <h2
            style={{
              backgroundImage: 'linear-gradient(90deg, #33B8A1, #102B33)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}
            className="text-4xl"
          >
            {title}
          </h2>
        )}
      </div>

      <div className="col-span-1 md:col-span-6 md:col-start-7">
        {text && <p className="text-body-color text-lg font-medium md:text-xl">{parse(text)}</p>}
        {buttonText && (
          <div className="mt-10 ">
            <ReferenceLink
              target={{
                link: buttonUrl ?? '#',
                target: buttonUrl ?? '#',
                type: 'link',
                openInNewWindow: false,
              }}
              className="inline-flex w-fit items-center justify-center rounded bg-primary py-3 px-8 text-center text-base font-semibold text-white hover:bg-secondary disabled:cursor-not-allowed disabled:bg-[#EDEDED]"
              // className="w-fit cursor-pointer rounded bg-primary py-2 px-4 text-white hover:bg-[#33B8A1] disabled:cursor-auto disabled:bg-[#EDEDED] disabled:text-[#808080]"
            >
              <span className="text-white">{buttonText}</span>
            </ReferenceLink>
          </div>
        )}
      </div>
    </motion.div>
  );
};

export default CZ13ImageWithText;
