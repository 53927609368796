import { useState, useEffect } from 'react';
import { Cloudinary } from '@cloudinary/url-gen';
import { AdvancedImage, lazyload, responsive } from '@cloudinary/react';
import { trim } from '@cloudinary/url-gen/actions/reshape';
import { pad } from '@cloudinary/url-gen/actions/resize';

const cld = new Cloudinary({
  cloud: {
    cloudName: process.env.NEXT_PUBLIC_ZYRES_CLOUDINARY_CLOUD_NAME,
  },
});

export const CustomImage = ({
  imgSrc,
  width,
  height,
  transformation = true,
  onLoad,
  placeholder = false,
}: {
  imgSrc: string;
  width: number;
  height: number;
  transformation?: boolean;
  placeholder?: boolean;
  onLoad?: () => void;
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const file = imgSrc;
  let image = cld.image(file);
  image = transformation
    ? image.setDeliveryType('fetch').reshape(trim()).resize(pad().width(width).height(height))
    : image.setDeliveryType('fetch');

  useEffect(() => {
    const img = new Image();
    img.src = image.toURL();
    img.onload = () => {
      setIsLoading(false);
      if (onLoad) {
        onLoad();
      }
    };
  }, [imgSrc, onLoad]);

  return (
    <>
      {placeholder ? (
        <div style={{ position: 'relative', width, height }}>
          {isLoading && <div style={{ width: '100%', height: '100%' }} />}
          <AdvancedImage
            cldImg={image}
            plugins={[responsive(), lazyload()]}
            style={{ visibility: isLoading ? 'hidden' : 'visible', height: '100%' }}
          />
        </div>
      ) : (
        <AdvancedImage cldImg={image} plugins={[responsive(), lazyload()]} />
      )}
    </>
  );
};
