import React, { useRef } from 'react';
import Link from 'next/link';
import { useFormat } from 'helpers/hooks/useFormat';
// import Image from 'frontastic/lib/image';
import { FullPageWidthWrapper } from '../full-bleed';
import { CldImage } from 'next-cloudinary';
import { useScroll, motion, useTransform } from 'framer-motion';

export default function Categories({ data }) {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ['0 1', '1.33 1'],
  });

  const bgProgress = useTransform(scrollYProgress, [0, 0.75], ['#F6F9FF', '#FFFFFF']);

  return (
    <motion.div>
      <FullPageWidthWrapper>
        <motion.div ref={ref} style={{ backgroundColor: bgProgress }} className="w-full">
          <section className="container mx-auto max-w-[1280px] px-4 pt-8 pb-4 font-urbanist sm:px-10 lg:px-4">
            <h2 className="text-dark my-4 font-urbanist text-heading-2 font-semibold">{data.ctaHeadline}</h2>
            <p className="mb-[42px] w-full font-urbanist text-base font-medium text-gray-100 lg:w-[466px]">
              {data.ctaSubtitle}
            </p>
            <div className="container mx-auto flex flex-wrap">
              {data.mainCategories.map((category, idx) => (
                <CategoriesItem
                  img={category.fgImage.media.file}
                  link={category.reference.link}
                  key={idx}
                  title={category.name}
                  text={category.text}
                />
              ))}
            </div>
          </section>
        </motion.div>
      </FullPageWidthWrapper>
    </motion.div>
  );
}

const CategoriesItem = ({ link, img, title, text }) => {
  const { formatMessage } = useFormat({ name: 'common' });
  const ref = useRef<HTMLDivElement>(null);

  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ['0 1', '1.5 1'],
  });

  const scaleProgress = useTransform(scrollYProgress, [0, 1], [0.8, 1]);
  const opacityProgress = useTransform(scrollYProgress, [0, 1], [0.7, 1]);

  return (
    <motion.div
      style={{ scale: scaleProgress, opacity: opacityProgress }}
      className="w-full rounded md:w-1/2 lg:w-1/3"
      ref={ref}
    >
      <div className="relative z-10 mx-auto mb-10 max-w-[370px] rounded border-[1px] border-[#D8D8D8] md:mx-0">
        {/* <img src={img} alt="category" className="h-[500px] w-full rounded object-cover" /> */}
        <div className="h-[500px] w-full rounded">
          <CldImage src={img} alt={title} layout="fill" className="h-full w-full rounded object-cover" />
        </div>
        <div className="absolute bottom-0 left-0 flex h-2/5 w-full flex-col items-start justify-end gap-6 py-10 px-5 md:h-3/6 md:gap-3">
          <div className="flex h-full flex-col items-start justify-start gap-2">
            <h3 className="block text-left text-xl font-bold text-white sm:text-3xl md:text-2xl xl:text-3xl">
              {title}
            </h3>
            <p className="max-w-[280px] text-left text-base font-medium text-white">{text}</p>
          </div>
          <div className="justify-self-end sm:self-center">
            <Link href={link}>
              <button className="inline-flex items-center justify-center rounded bg-primary py-[10px] px-7 text-center text-base font-semibold text-white hover:bg-opacity-90">
                {/* {button} */}
                {formatMessage({ id: 'viewAll', defaultMessage: 'View All' })}
              </button>
            </Link>
          </div>
        </div>
      </div>
    </motion.div>
  );
};
