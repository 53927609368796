import React, { FC } from 'react';
import { ProductProps, ProductGridItem } from '.';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

export const ProductGrid: FC<ProductProps> = ({ products, locale, className = '', fallback = false, loading }) => {
  const filteredProducts = products.filter((product) => product.variants.length > 0);
  return (
    <div
      className={`relative grid grid-cols-1 gap-8 rounded bg-white ${
        fallback
          ? 'sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4'
          : 'sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-2 xl:grid-cols-3'
      } ${className}`}
    >
      {loading
        ? Array.from({ length: 6 }).map((_, idx) => (
            <Skeleton key={idx} height={400} borderRadius={4} enableAnimation />
          ))
        : filteredProducts.map((product) => <ProductGridItem key={product.id} locale={locale} {...product} />)}
    </div>
  );
};
